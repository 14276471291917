import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'

import Layout from '../components/Layout'
import SEO from '../components/seo'

class Enterprise extends React.Component {
  render() {
    const siteTitle = 'Social Enterprise'
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <Container className="py-5">
          <Row>
            <Col id="employment" className="my-4">
              <h4 className="m-0 mb-2"></h4>
              <p>
                We bridge the gap between Community Based Organizations and
                organizations seeking talent. Finding good talent is hard, and
                there is a large population of Non-Traditional workers that are
                a viable piece of the untapped talent in the workforce. Working
                with organizations across the US that service these groups we
                can provide talent solutions for all your hiring needs.
              </p>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default Enterprise

export const EnterprisePageQuery = graphql`
  query EnterprisePageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
